import { Box, Divider, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import confidence from "../assets/images/About/Confidence.png";
import integrity from "../assets/images/About/Integrity.png";
import intellectual from "../assets/images/About/Intellectual.png";
import passion from "../assets/images/About/Passion.png";
import vision from "../assets/images/About/Vision statement-rafiki.png";
import mission from "../assets/images/About/mission.png";
import warmth from "../assets/images/About/warmth.png";
import ValuesCard from "../components/ValuesCard.js";
import about from "../assets/images/About/about.png";
import "../assets/css/AboutUs.css";
import logo from "../assets/images/About/logo.png";
const AboutUs = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Box
      p={4}
      overflow="hidden"
      minH="100vh"
      bgColor="#edf6f9"
      fontFamily="Oxygen"
    >
      <Flex
        mt={40}
        style={{ fontFamily: "Oxygen" }}
        flexDir={{ base: "column-reverse", md: "row" }} // Adjusted the flex direction for smaller screens
        justifyContent="center"
        alignItems="center"
        gap={10}
        flexWrap="wrap" // Added flexWrap to handle smaller screens
      >
        <Flex
          p={{ base: 4, md: 6 }}
          maxW={{ base: "100%", md: "55%" }} // Adjusted the max width for smaller screens
          textAlign={{ base: "center", md: "left" }} // Centered text for smaller screens
          bgColor="rgba(255, 255, 255, 0.9)"
          borderRadius="lg"
          boxShadow="lg"
          className="fade-in-bottom"
          flexDirection="column"
        >
          <Box display="inline-block">
            <div className="heading-2">
              <Heading
                style={{ fontFamily: "Oxygen" }}
                as="h2"
                size={{ base: "lg", md: "xl" }}
                fontWeight="bold"
                color="#22577a"
                mb={2}
                textAlign="center" // Centered text for smaller screens
              >
                About Us
              </Heading>
            </div>
            <Divider mt="2" />
            <Text
              lineHeight="2"
              listStyleType="disc"
              ml={{ base: 0, md: 8 }}
              fontSize={{ base: "md", md: "lg" }}
              mt={2}
              fontWeight="bold"
              color="#333"
            >
              We offer diverse services like{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                investment management
              </span>
              ,{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                tax planning
              </span>
              , and{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                estate planning
              </span>
              , evolving into one-stop solution for comprehensive financial
              solutions.
            </Text>
          </Box>
          <Box display="inline-block" mt={6}>
            <div className="heading-2">
              <Heading
                style={{ fontFamily: "Oxygen" }}
                as="h2"
                size={{ base: "lg", md: "xl" }}
                fontWeight="bold"
                color="#22577a"
                mb={2}
                textAlign="center" // Centered text for smaller screens
              >
                Vision
              </Heading>
            </div>
            <Divider mt="2" />
            <Text
              lineHeight="2"
              listStyleType="disc"
              ml={{ base: 0, md: 8 }}
              fontSize={{ base: "md", md: "lg" }}
              mt={2}
              fontWeight="bold"
              color="#333"
            >
              Striving to be the top financial advisory firm by achieving
              customer{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                financial goals
              </span>{" "}
              through{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                expert risk
              </span>{" "}
              and{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                wealth management solutions
              </span>{" "}
              , guided by our trusted consultants.
            </Text>
          </Box>
          <Box textAlign="left" mt={6}>
            <div className="heading-2">
              <Heading
                style={{ fontFamily: "Oxygen" }}
                as="h2"
                size={{ base: "lg", md: "xl" }}
                fontWeight="bold"
                color="#22577a"
                mb={2}
                textAlign="center"
              >
                Mission
              </Heading>
            </div>
            <Divider mt="2" />
            <Text
              lineHeight="2"
              listStyleType="disc"
              ml={{ base: 6, md: 8 }}
              fontSize={{ base: "md", md: "lg" }}
              mt={2}
              fontWeight="bold"
              color="#333"
            >
              We pledge to uphold utmost{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                integrity
              </span>{" "}
              and{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                professionalism
              </span>{" "}
              in our client relationships, aiming to understand your{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                financial situation
              </span>{" "}
              and deliver top-quality information, services, and products to
              help you{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                achieve
              </span>{" "}
              your goals.
            </Text>
          </Box>
        </Flex>
        <Box
          flex={{ base: 1, md: 1 }} // Equal width for both partitions
          maxW={{ base: "80%", md: "25%" }} // Adjusted width
          // textAlign="left"
          overflow="hidden" // Hide overflowing content
          display={{ base: "block", md: "block" }}
          bgColor="white"
          borderRadius="50px"
          boxShadow="lg"
          className="fade-in-bottom"
        >
          <Image
            src={logo}
            p={12}
            bgColor="white"
            alt="Your Image Alt Text"
            // maxH="1200px" // Increased the maximum height for the image
            width="100%" // Make the image width 100% to fit the container
            objectFit="cover" // Maintain aspect ratio and cover the container
            // borderRadius="full" // Rounded corners for a different shape
          />
        </Box>
      </Flex>
      <div className="heading-2">
        <Heading
          mt={{ base: 6, md: 12 }}
          style={{ fontFamily: "Oxygen" }}
          as="h2"
          size={{ base: "3xl", md: "3xl" }}
          fontWeight="bold"
          color="#22577a"
          textAlign="center"
        >
          Our Core Values
        </Heading>
      </div>
      <Flex
        alignItems="center"
        w="95%"
        mt={{ base: 12, md: 24 }}
        direction={{ base: "column", md: "row" }} // Adjusted the flex direction for smaller screens
        gap={{ base: "16", md: "8" }}
        mx="auto"
        justifyContent="space-evenly"
        className="fade-in-bottom"
        flexWrap="wrap"
      >
        <ValuesCard
          logoSrc={integrity}
          title="Integrity"
          description="Earn trust through your actions, embody integrity, and recognize the 
          privilege of being entrusted."
        />
        <ValuesCard
          logoSrc={intellectual}
          title="Intellectual Curiosity"
          description="Cultivate intellectual curiosity, embrace innovation, and strive for 
          ongoing professional development."
        />
        <ValuesCard
          logoSrc={confidence}
          title="Humble Confidence"
          description="Exude humble confidence and take immense pride in our 
          accomplishments."
        />
        <ValuesCard
          logoSrc={passion}
          title="Passion"
          description="Infuse passion, exude enthusiasm."
        />
        <ValuesCard
          logoSrc={warmth}
          title="Warmth"
          description="Radiate warmth through care, welcome, and kindness."
        />
      </Flex>
    </Box>
  );
};

export default AboutUs;
