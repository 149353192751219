import {
  Box,
  Flex,
  Heading,
  Image,
  ListItem,
  UnorderedList,
  Text,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import "../assets/css/ProductOfferings.css";
import ergo from "../assets/images/Product Offerings/Ergo1.png";
import motilal from "../assets/images/Product Offerings/Motilal.jpg";
import aia from "../assets/images/Product Offerings/TataAIA.webp";
import bajaj from "../assets/images/Product Offerings/bajaj.webp";
import care from "../assets/images/Product Offerings/care.jpg";
import hdfclife from "../assets/images/Product Offerings/hdfclife.jpg";
import icici from "../assets/images/Product Offerings/iciciPRU.jpg";
import iffco from "../assets/images/Product Offerings/iffco.webp";
import maxlife from "../assets/images/Product Offerings/maxlife.png";
import aig from "../assets/images/Product Offerings/tataAIG.png";
import sabseLife from "../assets/images/Product Offerings/SabseLife.jpg";
import mutualFunds from "../assets/images/Product Offerings/mutualFunds2.png";
import wealth1 from "../assets/images/Product Offerings/wealth1.webp";
import wealth2 from "../assets/images/Product Offerings/wealth2.jpg";
import legacy1 from "../assets/images/Product Offerings/legacy1.jpeg";
import legacy2 from "../assets/images/Product Offerings/lagecy2.webp";
import insurance1 from "../assets/images/Product Offerings/insurance1.jpeg";
import insurance2 from "../assets/images/Product Offerings/insurance2.jpeg";

const MotionFlex = motion(Flex);

const ProductOfferings = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const containerVariants = {
    hidden: { opacity: 0, width: "0%", rotateY: -90, originX: 0, originY: 0 },
    visible: {
      opacity: 1,
      width: "100%",
      rotateY: 0,
      transition: { duration: 0.8, ease: "easeInOut" },
    },
  };

  return (
    <Box
      pt={36}
      pb={12}
      px={{ base: 4, md: 24 }}
      overflow="hidden"
      minH="100vh"
      //   bgColor="#edf6f9"
      bgGradient="linear(to-r,white, #edf6f9 ,#e6f0f1)"
      fontFamily="Oxygen"
    >
      <Heading
        textAlign="center"
        as="h1"
        fontSize={{ base: "3xl", md: "5xl" }}
        fontWeight="bold"
        my={8}
        position="relative"
        display="inline-block"
        _after={{
          content: '""',
          position: "absolute",
          bottom: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "75%",
          height: "4px",
          bgColor: "teal.500",
          borderRadius: "2px",
        }}
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.1)"
      >
        Investment and Wealth Planning
      </Heading>
      <Flex
        mt={4}
        flexDir={{ base: "column", md: "row" }}
        // gap={10}
        // justifyContent="space-around"
        alignItems="center"
        // bgColor="red"
      >
        <Image
          mx="auto"
          src={mutualFunds}
          alt="Mutual Funds Sahi hai"
          maxW={{ base: "60%", md: "25%" }}
          // display={{ base: "none", md: "flex" }}
          // Adjusted the image size for smaller screens
          objectFit="contain"
          borderRadius="lg"
          boxShadow="lg"
        />
        <Flex
          borderRadius="2xl"
          // justifyContent="center"
          alignItems="center"
          mx="auto"
          // px={{ base: 2, md: 4 }}
          flexDir={{ base: "column", md: "column" }}
          // alignItems="center"
          w={{ base: "90%", md: "45vw" }}
          mt={4}
          boxShadow="lg"
          h={{ base: "auto", md: "auto" }} // Responsive height
          // bgGradient="linear(to-r, #858685, #1a1b1a)"
          bgColor="#252523"
          position="relative"
          py={{ base: 2, md: 4 }}
          className="hidden"
        >
          {/* <Image
            borderRadius="2xl"
            alignSelf="center"
            boxShadow="lg"
            bgColor="#f5cb5c"
            src={motilal}
            alt={motilal}
            h={{ base: "90px", md: "80px" }}
            ml={{ base: 0, md: -14 }}
            mb={{ base: 4, md: 0 }}
            maxW={{ base: "80%", md: "auto" }}
          /> */}

          <Heading
            style={{ fontFamily: "Oxygen" }}
            as="h2"
            size={{ base: "md", md: "md" }}
            fontWeight="bolder"
            color="#010101"
            mb={2}
            p={4}
            // mx="auto"
            textAlign="center"
            borderRadius="lg"
            bgColor="#ffc300"
            maxW="90%"
          >
            ASSOCIATE-PARTNER WITH MOTILAL OSWAL
          </Heading>
          <UnorderedList
            w="80%"
            fontSize={{ base: "sm", md: "lg" }}
            color="#f1d690"
          >
            <ListItem
              fontWeight="500"
              // color="#333"
              fontSize={{ base: "md", md: "md" }}
            >
              We've adopted a unique partnership model with Motilal Oswal,
              providing a broad range of financial products to assist
              individuals and entities in managing their personal and corporate
              finances.{" "}
            </ListItem>
            <br />
            <ListItem
              fontWeight="500"
              // color="#333"
              fontSize={{ base: "md", md: "md" }}
            >
              Collaborating with Motilal Oswal to address diverse client needs,
              goals, and priorities in managing finance and disposable income.
            </ListItem>
          </UnorderedList>
        </Flex>
      </Flex>
      <Flex justifyContent="center" mt={8} gap={24} flexDir="row" mx="auto">
        <Box
          flex={{ base: 1, md: 2 }}
          maxW={{ base: "90%", md: "20%" }}
          textAlign="left"
          bgColor="rgba(255, 255, 255, 0.9)"
          borderRadius="lg"
          boxShadow="lg"
          display={{ base: "none", md: "block" }}
          position="relative"
        >
          <Image
            src={wealth1}
            alt="Your Image Alt Text"
            maxH="600px"
            width="100%"
            objectFit="cover"
            borderRadius="lg"
            boxShadow="lg"
            // scale={2}
          />
          {/* <Box
            bgColor="rgba(0, 0, 0, 0.7)"
            p={2} 
            borderBottomRadius="lg"
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            textAlign="center"
          >
            <Text color="white" fontWeight="bold" fontSize="22px">
              {" "}
       
              Investment Planning
            </Text>
          </Box> */}
        </Box>

        <Image
          src={wealth2}
          alt="Your Image Alt Text"
          bgColor="rgba(255, 255, 255, 0.9)"
          display={{ base: "none", md: "block" }}
          maxH="220px"
          // width="100%"
          // objectFit="cover"
          borderRadius="lg"
          boxShadow="lg"
          // scale={2}
        />
        {/* <Box
            bgColor="rgba(0, 0, 0, 0.7)"
            p={2} // Adjust the padding as needed
            borderBottomRadius="lg"
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            textAlign="center"
          >
            <Text color="white" fontWeight="bold" fontSize="24px">
              {" "}
              
              Wealth Planning
            </Text>
          </Box> */}
      </Flex>
      <Heading
        as="h1"
        fontSize={{ base: "3xl", md: "5xl" }}
        fontWeight="bold"
        mt={8}
        position="relative"
        display="inline-block"
        _after={{
          content: '""',
          position: "absolute",
          bottom: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "75%",
          height: "4px",
          bgColor: "teal.500",
          borderRadius: "2px",
        }}
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.1)"
      >
        Insurance
      </Heading>
      <Flex flexDir="column">
        <Flex
          flexDir={{ base: "column", md: "row" }}
          gap={10}
          justifyContent="center"
          alignItems="center"
        >
          <MotionFlex
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            mt={12}
            p={{ base: 4, md: 6 }}
            maxW={{ base: "100%", md: "70%" }}
            textAlign="left"
            bgColor="rgba(255, 255, 255, 0.9)"
            borderRadius="lg"
            boxShadow="lg"
            ml={{ base: 0, md: 8 }}
            //   mx="auto"
          >
            <UnorderedList
              lineHeight="2"
              listStyleType="disc"
              ml={{ base: 6, md: 8 }}
              fontSize={{ base: "md", md: "lg" }}
              mt={2}
            >
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                <b style={{ color: "#0053a9" }}>Sabse Pehle</b> Life Insurance.
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                <span className="underlineText" style={{ color: "#e89f69" }}>
                  Solicitation
                </span>{" "}
                is involved in the matter of insurance.
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Insurance is buying of{" "}
                <span className="underlineText" style={{ color: "#e89f69" }}>
                  Risk
                </span>
                , at a{" "}
                <span className="underlineText" style={{ color: "#e89f69" }}>
                  cost
                </span>
                .
              </ListItem>

              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Insurance is a{" "}
                <span className="underlineText" style={{ color: "#e89f69" }}>
                  financial arrangement
                </span>{" "}
                that provides protection against specified risks,{" "}
                <span className="underlineText" style={{ color: "#e89f69" }}>
                  offering compensation
                </span>{" "}
                or coverage for{" "}
                <span className="underlineText" style={{ color: "#e89f69" }}>
                  potential losses
                </span>{" "}
                in exchange for premium payments.
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Insurance is like a{" "}
                <span className="underlineText" style={{ color: "#e89f69" }}>
                  safety net
                </span>{" "}
                for unexpected events. You invest a small amount regularly, and
                in return, if something bad happens, the insurance
                <span className="underlineText" style={{ color: "#e89f69" }}>
                  {" "}
                  helps
                </span>{" "}
                cover the costs or losses, giving you financial protection and{" "}
                <span className="underlineText" style={{ color: "#e89f69" }}>
                  peace of mind
                </span>
                .
              </ListItem>
            </UnorderedList>
          </MotionFlex>
          <Image
            src={sabseLife}
            alt="Vision"
            maxW={{ base: "60%", md: "20%" }}
            // display={{ base: "none", md: "flex" }}
            // Adjusted the image size for smaller screens
            objectFit="contain"
            borderRadius="md"
            boxShadow="lg"
          />
        </Flex>
        <Flex justifyContent="center" mt={8} gap={24} flexDir="row" mx="auto">
          <Box
            flex={{ base: 1, md: 2 }}
            maxW={{ base: "90%", md: "20%" }}
            textAlign="left"
            bgColor="rgba(255, 255, 255, 0.9)"
            borderRadius="lg"
            boxShadow="lg"
            display={{ base: "none", md: "block" }}
            position="relative"
          >
            <Image
              src={insurance1}
              alt="Your Image Alt Text"
              maxH="600px"
              width="100%"
              objectFit="cover"
              borderRadius="lg"
              boxShadow="lg"
              // scale={2}
            />
            {/* <Box
              bgColor="rgba(0, 0, 0, 0.7)"
              p={2} 
              borderBottomRadius="lg"
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              textAlign="center"
            >
              <Text color="white" fontWeight="bold" fontSize="22px">
                {" "}
       
                Insurance
              </Text>
            </Box> */}
          </Box>
          <Box
            flex={{ base: 1, md: 2 }}
            maxW={{ base: "90%", md: "20%" }}
            textAlign="left"
            bgColor="rgba(255, 255, 255, 0.9)"
            borderRadius="lg"
            boxShadow="lg"
            display={{ base: "none", md: "block" }}
            position="relative"
          >
            <Image
              src={insurance2}
              alt="Your Image Alt Text"
              maxH="600px"
              width="100%"
              objectFit="cover"
              borderRadius="lg"
              boxShadow="lg"
              // scale={2}
            />
            {/* <Box
              bgColor="rgba(0, 0, 0, 0.7)"
              p={2} 
              borderBottomRadius="lg"
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              textAlign="center"
            >
              <Text color="white" fontWeight="bold" fontSize="24px">
                {" "}
        
                Wealth Planning
              </Text>
            </Box> */}
          </Box>
        </Flex>

        {/* <Flex
          flexDir={{ base: "row", md: "row" }}
          justifyContent="space-around"
          alignItems="center"
          p={8}
          gap={10}
          flexWrap="wrap"
          className="hidden"
        >
          <Image
            src={aia}
            alt="Logo 1"
            background="white"
            maxW={{ base: "80px", md: "80px" }}
            h={{ base: "80px", md: "80px" }}
          />
          <Image
            src={icici}
            alt="Logo 1"
            background="white"
            maxW={{ base: "80px", md: "80px" }}
            h={{ base: "80px", md: "80px" }}
          />
          <Image
            src={hdfclife}
            alt="Logo 1"
            background="white"
            maxW={{ base: "80px", md: "80px" }}
            h={{ base: "80px", md: "80px" }}
          />
          <Image
            src={maxlife}
            alt="Logo 1"
            background="white"
            maxW={{ base: "80px", md: "90px" }}
            h={{ base: "80px", md: "70px" }}
          />
          <Image
            src={bajaj}
            alt="Logo 1"
            background="white"
            maxW={{ base: "80px", md: "80px" }}
            h={{ base: "80px", md: "80px" }}
          />
          <Image
            src={care}
            alt="Logo 1"
            background="white"
            maxW={{ base: "80px", md: "90px" }}
            h={{ base: "80px", md: "70px" }}
          />
          <Image
            src={aig}
            alt="Logo 1"
            background="white"
            maxW={{ base: "80px", md: "90px" }}
            h={{ base: "80px", md: "70px" }}
          />
          <Image
            src={ergo}
            alt="Logo 1"
            background="white"
            maxW={{ base: "100px", md: "90px" }}
            h={{ base: "80px", md: "70px" }}
          />
          <Image
            src={iffco}
            alt="Logo 1"
            background="white"
            maxW={{ base: "80px", md: "90px" }}
            h={{ base: "80px", md: "70px" }}
          />
        </Flex> */}
      </Flex>
      <Heading
        as="h1"
        fontSize={{ base: "3xl", md: "5xl" }}
        fontWeight="bold"
        mt={8}
        position="relative"
        display="inline-block"
        _after={{
          content: '""',
          position: "absolute",
          bottom: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "75%",
          height: "4px",
          bgColor: "teal.500",
          borderRadius: "2px",
        }}
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.1)"
      >
        Wealth Advisory & Legacy/Succession Planning
      </Heading>
      <Flex
        mt={{ base: 8, md: 4 }}
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: 0, md: 10 }}
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={mutualFunds}
          alt="Mutual Funds Sahi hai"
          maxW={{ base: "60%", md: "20%" }}
          // display={{ base: "none", md: "flex" }}
          // Adjusted the image size for smaller screens
          objectFit="contain"
          borderRadius="lg"
          boxShadow="lg"
        />
        <Flex
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          mt={12}
          p={{ base: 4, md: 6 }}
          maxW={{ base: "100%", md: "70%" }}
          textAlign="left"
          bgColor="rgba(255, 255, 255, 0.9)"
          borderRadius="lg"
          boxShadow="lg"
          ml={{ base: 6, md: 8 }}
          className="hidden"
        >
          <UnorderedList
            lineHeight="2"
            listStyleType="disc"
            ml={{ base: 6, md: 8 }}
            fontSize={{ base: "md", md: "lg" }}
            mt={2}
          >
            <ListItem
              fontWeight="bold"
              color="#333"
              fontSize={{ base: "md", md: "lg" }}
            >
              Wealth advisory involves managing and optimizing{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                financial assets
              </span>
              . It is about handling and{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                improving
              </span>{" "}
              your money and investments.
            </ListItem>
            <ListItem
              fontWeight="bold"
              color="#333"
              fontSize={{ base: "md", md: "lg" }}
            >
              Legacy planning involves preparing to transfer assets to{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                future generations
              </span>
              , ensuring a{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                smooth transition
              </span>{" "}
              to the next.
            </ListItem>
          </UnorderedList>
        </Flex>
      </Flex>
      <Flex justifyContent="center" mt={8} gap={24} flexDir="row" mx="auto">
        <Box
          flex={{ base: 1, md: 2 }}
          maxW={{ base: "90%", md: "20%" }}
          textAlign="left"
          bgColor="rgba(255, 255, 255, 0.9)"
          borderRadius="lg"
          boxShadow="lg"
          display={{ base: "none", md: "block" }}
          position="relative"
        >
          <Image
            src={legacy2}
            alt="Your Image Alt Text"
            maxH="600px"
            width="100%"
            objectFit="cover"
            borderRadius="lg"
            boxShadow="lg"
          />
          {/* <Box
            bgColor="rgba(0, 0, 0, 0.7)"
            p={2} 
            borderBottomRadius="lg"
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            textAlign="center"
          >
            <Text color="white" fontWeight="bold" fontSize="22px">
              {" "}
           
              Wealth Advisory
            </Text>
          </Box> */}
        </Box>

        <Image
          src={legacy1}
          alt="Your Image Alt Text"
          bgColor="rgba(255, 255, 255, 0.9)"
          display={{ base: "none", md: "block" }}
          // maxH="600px"
          // width="100%"
          // objectFit="cover"
          borderRadius="lg"
          boxShadow="lg"
        />
        {/* <Box
            bgColor="rgba(0, 0, 0, 0.7)"
            p={2} 
            borderBottomRadius="lg"
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            textAlign="center"
          >
            <Text color="white" fontWeight="bold" fontSize="24px">
              {" "}
     
              Legacy Planning
            </Text>
          </Box> */}
      </Flex>
    </Box>
  );
};

export default ProductOfferings;
