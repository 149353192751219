import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import mutualFund from "../assets/images/Expertise/Mutual Funds.png";
import portfolio from "../assets/images/Expertise/Portfolio.png";
import businessPlanning from "../assets/images/Expertise/businessPlanning.png";
import equity from "../assets/images/Expertise/equity.png";
import generalInsurance from "../assets/images/Expertise/generalInsurance.png";
import groupInsurance from "../assets/images/Expertise/groupInsurance.jpg";
import healthInsurance from "../assets/images/Expertise/healthInsurance.png";
import legacyPlanning from "../assets/images/Expertise/legacyPlanning.jpg";
import lifeInsurance from "../assets/images/Expertise/lifeInsurance.png";
import mwpa from "../assets/images/Expertise/mwpa.png";
import pensionPlanning from "../assets/images/Expertise/pensionPlanning.png";
import wealthAdvisory from "../assets/images/Expertise/wealthAdvisory.png";
import ServicesCard from "../components/ServicesCard.js";

const Expertise = () => {
  return (
    <Box overflow="hidden">
      <Flex
        mt={36}
        // w="97.5vw"
        justifyContent="center"
        flexDir={{ base: "column", md: "column" }}
        align="center"
      >
        <Flex
          w="100%"
          flexDir={{ sm: "column", md: "row" }}
          flexWrap="wrap"
          justifyContent="center"
        >
          <ServicesCard
            imageUrl={mutualFund}
            title="Mutual Funds"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          <ServicesCard
            imageUrl={portfolio}
            title="Portfolio Management Scheme"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
          <ServicesCard
            imageUrl={equity}
            title="Equity"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          <ServicesCard
            imageUrl={healthInsurance}
            title="Health Insurance"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
          <ServicesCard
            imageUrl={lifeInsurance}
            title="Life Insurance"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          <ServicesCard
            imageUrl={pensionPlanning}
            title="Pension Planning"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          <ServicesCard
            imageUrl={businessPlanning}
            title="Business Insurance"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          <ServicesCard
            imageUrl={mwpa}
            title="Married Women's Property Act"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
          <ServicesCard
            imageUrl={groupInsurance}
            title="Group Insurance"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          <ServicesCard
            imageUrl={generalInsurance}
            title="General Insurance"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          <ServicesCard
            imageUrl={legacyPlanning}
            title="Legacy Planning"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />

          <ServicesCard
            imageUrl={wealthAdvisory}
            title="Wealth Advisory"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        </Flex>
        <Flex w="100%" flexDir={{ base: "column", md: "row" }}></Flex>
        <Flex w="100%" flexDir={{ base: "column", md: "row" }}></Flex>
        {/* Add more cards here */}
      </Flex>
    </Box>
  );
};

export default Expertise;
