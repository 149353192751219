import "../assets/css/ServicesCard.css";
import { Link } from "react-router-dom";
import { Button, Flex } from "@chakra-ui/react";

const ServicesCard = ({ imageUrl, title, text }) => {
  return (
    <div className="card">
      <img src={imageUrl} alt={title} />

      <div className="intro">
        <h1>{title}</h1>
        {/* <p>{text}</p> */}

        <Flex justifyContent="center">
          <Link to="/contactus">
            <Button mt={10} mx="auto">
              Enquire Now
            </Button>
          </Link>
        </Flex>
      </div>
    </div>
  );
};

export default ServicesCard;
