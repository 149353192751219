import React from "react";
import { Flex, Image, Text, Heading } from "@chakra-ui/react";

const ValuesCard = ({ logoSrc, title, description }) => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      // w="320px"
      w={{ base: "250px", md: "15.5vw" }}
      minW="220px"
      mt={12}
      borderTopLeftRadius="20%"
      borderBottomRightRadius="20%"
      boxShadow="lg"
      p={8}
      h="320px"
      maxH="360px"
      bgColor="#333533"
    >
      <Image
        borderTopLeftRadius="20%"
        borderBottomRightRadius="20%"
        boxShadow="lg"
        bgColor="#f5cb5c"
        src={logoSrc}
        alt={title}
        mt={-24} // Fixed margin-top
        p={6}
        // h="160px"
        minW="100px"
        w={{ base: "120px", md: "7.5vw" }}
      />
      <Flex mt={4} flexDir="column" alignItems="center">
        <Heading
          fontSize="3xl"
          color="#f5cb5c"
          fontFamily="Oxygen"
          textAlign="center"
        >
          {title}
        </Heading>
        <Text fontSize="md" mt={4} color="#f5cb5c">
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ValuesCard;
