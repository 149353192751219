import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import mutualfunds from "../assets/images/Home/MutualFunds.png";
import equity from "../assets/images/Home/equity.png";
import financial from "../assets/images/Home/financial.png";
import generalInsurance from "../assets/images/Home/general-insurance.png";
import healthInsurance from "../assets/images/Home/health-insurance.png";
import insurance from "../assets/images/Home/insuranceImage.png";
import investmentImage from "../assets/images/Home/investmentImage.png";
import lifeInsurance from "../assets/images/Home/life-insurance.png";
import planning from "../assets/images/Home/planning.png";
import portfolio from "../assets/images/Home/portfolio.png";
import privateEquity from "../assets/images/Home/private-equity.png";
import wealth from "../assets/images/Home/wealth.png";
import ImageSlider from "../components/ImageSlider.js";

import brainStorming from "../assets/images/Home/brainStorming2.png";
import family from "../assets/images/Home/family.png";
import bg from "../assets/images/Home/bg.png";
import happy from "../assets/images/Home/happy.png";
import yoga from "../assets/images/Home/yoga.png";
import work from "../assets/images/Home/work.png";
import img1 from "../assets/images/Home/img1.png";
import img2 from "../assets/images/Home/img2.png";
import img3 from "../assets/images/Home/img3.jpeg";
import img4 from "../assets/images/Home/img4.png";
import img5 from "../assets/images/Home/img5.png";
import img6 from "../assets/images/Home/img6.png";
import img7 from "../assets/images/Home/img7.png";
import "../assets/css/ImageSlider.css";
const offerings = [
  {
    id: 1,
    title: "Mutual Funds",
    imagePath: mutualfunds,
  },
  {
    id: 2,
    title: "Equity",
    imagePath: equity,
  },
  {
    id: 3,
    title: "Portfolio Management Scheme",
    imagePath: portfolio,
  },
  {
    id: 4,
    title: "Financial Planning",
    imagePath: financial,
  },
  {
    id: 5,
    title: "Health Insurance",
    imagePath: healthInsurance,
  },
  {
    id: 6,
    title: "Life Insurance",
    imagePath: lifeInsurance,
  },
  {
    id: 7,
    title: "General Insurance",
    imagePath: generalInsurance,
  },
  {
    id: 8,
    title: "Private Equity",
    imagePath: privateEquity,
  },
];
const Home = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const opacity = 1 - scrollY / 800;
  const scale = 1 + scrollY * 0.0002;
  const imageStyle = {
    transform: `scale(${scale})`,
    opacity: opacity < 0 ? 0 : opacity > 1 ? 1 : opacity,
    transition: "transform 0.01s ease-in-out,opacity 0.03s ease-in-out",
  };

  const circleStyle = {
    fontFamily: "Oxygen",
    width: "200px",
    height: "180px",
    borderRadius: "30%",
    // backgroundColor: "gray.900",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // borderRadius: "full",

    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    margin: "0.5rem",
    overflow: "hidden",
    backgroundColor: "white",
    // color: "#e29578",
  };

  const imgObj1 = {
    img: img1,
    imgText: "Wealth Management & Financial Planning",
  };

  const imgObj2 = {
    img: img2,
    imgText: "Planned",
  };
  const imgObj3 = {
    img: img7,
    imgText: "Planned",
  };
  const imgObj4 = {
    img: img3,
    imgText: "Planned",
  };
  const imgObj5 = {
    img: img4,
    imgText: "Planned",
  };
  const imgObj6 = {
    img: img5,
    imgText: "Planned",
  };
  const imgObj7 = {
    img: img6,
    imgText: "Planned",
  };
  const images = [
    imgObj1,
    imgObj2,
    imgObj3,
    imgObj4,
    imgObj5,
    imgObj6,
    imgObj7,
  ];
  const imageText = [
    "Wealth Management & Financial Planning",
    "brain storming on financial planning",
    "Happy Life",
  ];

  return (
    <Box overflow="hidden" minH="100vh">
      {/* <Box
        bgImage={planning}
        alt="Financial planning and wealth management"
        bgSize="cover"
        bgPosition="center"
        h="80vh"
        position="relative"
        style={imageStyle}
      >
        <Box
          position="absolute"
          top="90%"
          left="30%"
          transform="translate(-50%, -50%)"
          textAlign="center"
        >
          <Heading as="h1" size="2xl" color="black.800" fontFamily="Oxygen">
            Wealth Management & Financial Planning
          </Heading>
        </Box>
      </Box> */}
      <Box h="10vh" bgColor="transparent"></Box>
      <ImageSlider images={images} imageText={imageText} />
      <Flex
        bgImage={bg}
        bgSize="cover"
        bgPosition="center"
        // h="20vh"
        position="relative"
        // mt={1}
        py={8}
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        bgColor="#22577a"
      >
        <Heading
          as="h1"
          size={{ base: "lg", md: "2xl" }}
          // mt="8"
          mb="6"
          mx="auto"
          // ml="10%"
          // color="#22577a"
          color="black.400"
          fontFamily="Oxygen"
        >
          OUR SERVICES
        </Heading>
        <Flex
          // gap={4}
          // ml={8}
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-around"
          alignItems="center"
          w="70%"
          // bgColor="#e0e0e0"
          bgColor="transparent"
          className="hidden"
          flexWrap="wrap"
        >
          {offerings.map((offering) => (
            <Box
              key={offering.id}
              style={circleStyle}
              _hover={{
                transform: "scale(1.4)",
              }}
              transition="transform 0.3s ease-in-out"
              className="logo hidden"
              p={12}
            >
              <Flex direction="column" alignItems="center">
                <Image
                  src={offering.imagePath}
                  alt="Quality Logo"
                  maxW="60px"
                />
                <Text
                  fontSize={{
                    base: "md",
                    md: "xl",
                  }}
                  textAlign="center"
                  fontWeight="bold"
                  color="#22577a"
                  mt={{
                    base: "0.25rem",
                    md: "0.75rem",
                  }}
                >
                  {offering.title}
                </Text>
              </Flex>
            </Box>
          ))}
        </Flex>
      </Flex>

      <Box mt="4" maxW="100vw" p={{ base: 4, md: 8 }} overflow="hidden">
        <Slider {...sliderSettings}>
          <Box key={1}>
            <SliderBox
              imgUrl={investmentImage}
              title="Investment"
              content="Investment is crucial for growing wealth and achieving financial goals"
              infoColumn1={[
                "Financial Goals",
                "Wealth Accumulation",
                "Inflation Hedge",
                "Diversification",
                "Retirement Planning",
              ]}
              infoColumn2={[
                "Income Generation",
                "Capital Appreciation",
                "Emergency Fund Growth",
                "Tax Efficiency",
                "Economic Growth",
              ]}
            />
          </Box>
          <Box key={2}>
            <SliderBox
              imgUrl={wealth}
              title="Wealth Creation"
              content="Wealth creation is vital for securing financial independence and improving overall quality of life"
              infoColumn1={[
                "Financial Independence",
                "Security & Stability",
                "Debt Reduction",
                "Legacy Building",
                "Opportunity Pursuit",
              ]}
              infoColumn2={[
                "Quality of Life Improvement",
                "Retirement Planning",
                "Philanthropy & Giving Back to Society",
                "Entrepreneurial Ventures",
                "Peace of Mind",
              ]}
            />
          </Box>

          <Box key={3}>
            <SliderBox
              imgUrl={insurance}
              title="Insurance"
              content="Insurance is necessary to provide financial protection against unforeseen risks and uncertainties"
              infoColumn1={[
                "Risk Mitigation",
                "Family’s Financial Security",
                "Peace of Mind",
              ]}
              infoColumn2={[
                "Wealth Protection",
                "Managing Healthcare Costs",
                "Business Continuity",
              ]}
            />
          </Box>
        </Slider>
      </Box>
    </Box>
  );
};
const SliderBox = ({ imgUrl, title, content, infoColumn1, infoColumn2 }) => {
  return (
    <Box
      bgColor="#edf6f9"
      mx="auto"
      display="flex"
      flexDir={{ base: "column", md: "row" }}
      w={{ base: "90vw", md: "75vw" }}
      justifyContent="center"
      overflow="hidden"
      borderRadius="lg"
      minH="40vh"
    >
      <Box
        width={{ base: "100%", md: "30%" }}
        maxH={{ sm: "200px", md: "100%" }}
        bgColor="#83c5be"
        style={{ overflow: "hidden" }}
        borderRadius="lg"
        p={{ base: 0, md: 6 }}
      >
        <Image
          src={imgUrl}
          alt={title}
          width="100%"
          // style={{ objectFit: "contain", height: "100%" }}
          objectFit={{ sm: "contain", md: "cover" }}
          maxH={{ sm: "200px", md: "100%" }}
        />
      </Box>
      <Box
        flex="1"
        px="4"
        py={{ base: 4, md: 4 }}
        textAlign="center"
        bgColor="#edf6f9"
        overflow="hidden"
      >
        <Heading
          as="h2"
          size={{ base: "xl", md: "2xl" }}
          color="black.800"
          fontFamily="Oxygen"
        >
          {title}
        </Heading>
        <Divider mt="2" />
        <Text fontSize={{ base: "md", md: "lg" }} color="black.800" mt="2">
          {content}
        </Text>
        <Flex
          flexDir="row"
          // bgColor="red"
          h="60%"
          justifyContent="space-around"
          mt={4}
        >
          <Flex flexDir="column">
            <UnorderedList
              lineHeight="2"
              listStyleType="disc"
              ml={{ base: 6, md: 8 }}
              fontSize={{ base: "md", md: "lg" }}
              mt={2}
              fontFamily="Oxygen"
            >
              {infoColumn1.length > 0 &&
                infoColumn1.map((info, index) => (
                  <ListItem
                    key={index}
                    textAlign="left"
                    fontWeight="bold"
                    color="#333"
                    fontSize={{ base: "md", md: "lg" }}
                  >
                    {info}
                  </ListItem>
                ))}
            </UnorderedList>
          </Flex>
          <Flex flexDir="column">
            <UnorderedList
              lineHeight="2"
              listStyleType="disc"
              ml={{ base: 6, md: 8 }}
              fontSize={{ base: "md", md: "lg" }}
              mt={2}
              fontFamily="Oxygen"
            >
              {infoColumn2.length > 0 &&
                infoColumn2.map((info, index) => (
                  <ListItem
                    key={index}
                    textAlign="left"
                    fontWeight="bold"
                    color="#333"
                    fontSize={{ base: "md", md: "lg" }}
                  >
                    {info}
                  </ListItem>
                ))}
            </UnorderedList>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Home;
