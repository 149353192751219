import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Link,
  Heading,
} from "@chakra-ui/react";

const Informatives = () => {
  const [newsData, setNewsData] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;
  const url = `https://newsdata.io/api/1/news?apikey=${apiKey}&q=stock%20news&country=in&language=en&category=business`;
  const errorMessage =
    "Sorry for the inconvenience. We are working on it, please come back later.";

  useEffect(() => {
    getNewsData();
  }, []);
  const getNewsData = async () => {
    try {
      const response = await axios.get(url);
      setNewsData(response.data.results);
    } catch (error) {
      console.log(error.response.data.results.message);
    }
  };
  return (
    <Box
      minH="92.2vh"
      pt={36}
      pb={8}
      px={4}
      style={{
        background: "rgb(146,247,255)",
        background:
          "linear-gradient(90deg, rgba(146,247,255,0.2861519607843137) 33%, rgba(255,237,207,0.5270483193277311) 100%)",
      }}
    >
      <Heading
        textAlign="center"
        as="h1"
        fontSize={{ base: "3xl", md: "5xl" }}
        fontWeight="bold"
        mt={8}
        ml={{ base: 4, md: 24 }}
        position="relative"
        display="inline-block"
        _after={{
          content: '""',
          position: "absolute",
          bottom: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "75%",
          height: "4px",
          bgColor: "teal.500",
          borderRadius: "2px",
        }}
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.1)"
      >
        Informative Links
      </Heading>
      <Box
        mx="auto"
        mt={10}
        boxShadow="lg"
        borderRadius="lg"
        w={{ base: "100%", md: "90%" }}
      >
        <TableContainer
          w="100%"
          overflowX="auto"
          style={{ fontFamily: "Oxygen" }}
        >
          <Table variant="striped" bgColor="white" colorScheme="teal">
            <Thead>
              {newsData.length > 0 && (
                <Tr>
                  <Th display={{ base: "none", md: "table-cell" }}>Sr. No.</Th>

                  <Th w={{ base: "80%", md: "auto" }}>News Title</Th>

                  <Th w={{ base: "20%", md: "auto" }}>Website Link</Th>
                </Tr>
              )}
            </Thead>
            <Tbody>
              {newsData.length > 0 ? (
                newsData.map((news, index) => (
                  <Tr key={index}>
                    <Td display={{ base: "none", md: "table-cell" }}>
                      {index + 1}
                    </Td>
                    <Td
                      color="blue"
                      fontSize={{ base: "sm", md: "md" }}
                      w={{ base: "80%", md: "auto" }}
                      whiteSpace="normal"
                    >
                      <Link
                        href={news.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {news.title}
                      </Link>
                    </Td>
                    <Td
                      fontSize={{ base: "sm", md: "lg" }}
                      color="blue"
                      w={{ base: "20%", md: "auto" }}
                    >
                      <u>
                        <Link
                          href={news.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Article
                        </Link>
                      </u>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Td colSpan={3}>
                  <Box w="100%" textAlign="center" p={4}>
                    <Heading
                      fontSize={{ base: "lg", md: "3xl" }}
                      fontFamily="Oxygen"
                      whiteSpace="normal"
                      wordBreak="break-word"
                      overflowWrap="break-word"
                    >
                      {errorMessage}
                    </Heading>
                  </Box>
                </Td>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Informatives;
