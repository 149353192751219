import { Flex, Icon, Text, Image } from "@chakra-ui/react";
import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import instagram from "../assets/images/Footer/122.jpg";
import facebook from "../assets/images/Footer/fb.png";
import linkedin from "../assets/images/Footer/linkedin.png";
import pinterest from "../assets/images/Footer/pinterest.png";
import twitter from "../assets/images/Footer/twitter.png";
import youtube from "../assets/images/Footer/youtube.png";

console.warn = () => {};

const Footer = () => {
  console.warn = () => {};

  const socialMediaLinks = {
    instagram:
      "https://www.instagram.com/wealth.jewels?igsh=MXZiYW9hcGpuYm12ZQ==",
    facebook:
      "https://www.facebook.com/profile.php?id=61556008422535&mibextid=LQQJ4d",
    linkedin: "https://www.linkedin.com/company/wealth-jewels",
    twitter: "https://twitter.com/WealthJewels48",
    youtube: "https://www.youtube.com/@wealthjewels",
  };

  const openSocialMedia = (platform) => {
    const socialMediaLink = socialMediaLinks[platform];
    window.open(socialMediaLink, "_blank");
  };

  return (
    <Flex
      bg="#04262b"
      color="white"
      py={4}
      justifyContent="center"
      alignItems="center"
      transition="background 0.3s ease"
      // _hover={{ bg: "alphaBlack.900" }}
    >
      <Image
        w={{ base: "40px", md: "40px" }}
        h={{ base: "40px", md: "40px" }}
        borderRadius="full"
        ml={{ base: 4, md: 8 }}
        src={instagram}
        alt="Instagram"
        cursor="pointer"
        objectFit="cover"
        _hover={{ transform: "scale(1.2)" }}
        onClick={() => openSocialMedia("instagram")}
      />

      <Image
        w="40px"
        h="40px"
        ml={{ base: 4, md: 8 }}
        src={facebook}
        alt="Facebook"
        cursor="pointer"
        _hover={{ transform: "scale(1.2)" }}
        onClick={() => openSocialMedia("facebook")}
      />
      <Image
        w="40px"
        h="40px"
        ml={{ base: 4, md: 8 }}
        src={linkedin}
        alt="LinkedIn"
        cursor="pointer"
        _hover={{ transform: "scale(1.2)" }}
        onClick={() => openSocialMedia("linkedin")}
      />

      <Image
        w="40px"
        h="40px"
        ml={{ base: 4, md: 8 }}
        src={twitter}
        alt="Twitter"
        cursor="pointer"
        _hover={{ transform: "scale(1.2)" }}
        onClick={() => openSocialMedia("twitter")}
      />
      <Image
        w="40px"
        h="40px"
        ml={{ base: 4, md: 8 }}
        src={youtube}
        alt="YouTube"
        cursor="pointer"
        _hover={{ transform: "scale(1.2)" }}
        onClick={() => openSocialMedia("youtube")}
      />
    </Flex>
  );
};

export default Footer;
