// Navbar.js
import { Box, Flex, List, ListItem, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/Navbar.css";
// import logo from "../assets/images/Home/logo.jpg";
import logo from "../assets/images/Logo2.png";
const Navbar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleClick = (index) => {
    setActiveLink(index);
    closeNav();
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      // bg="#006d77"
      bg="white"
      position="fixed"
      zIndex="2"
      w="100vw"
      color="white"
      px={{ base: "10px", md: "10px" }}
      fontFamily="Sora"
      boxShadow="md"
      overflow="hidden"
      minH={{ base: "10vh", md: "17vh" }}
    >
      <div className="nav-bar nav-links collapse navbar-collapse">
        <Flex gap={10}>
          <Box
            className={`nav-icon ${isNavOpen ? "show" : ""}`}
            display={{ sm: "block", md: "none" }}
            ml={2}
            onClick={toggleNav}
            color="#006d77"
            alignSelf="center"
          >
            {isNavOpen ? "✕" : "☰"}
          </Box>
          <a href="/">
            <div className="nav-logo">
              <img
                src={logo}
                alt="Your Logo"
                display="block"
                className="nav-item"
              />
            </div>
          </a>
        </Flex>
        <Box
          className={`nav-links ${isNavOpen ? "show" : ""}`}
          display={{ base: isNavOpen ? "block" : "none", md: "flex" }}
        >
          {/* <Box
          className="nav-icon"
          display={{ base: isNavOpen ? "block" : "none", md: "none" }}
          onClick={closeNav}
          ml={12}
          color="white"
          position="absolute"
        >
          ✕
        </Box> */}
          <List className="navbar-nav">
            <Link to="/about">
              <ListItem
                fontWeight="bolder"
                className={`nav-item ${activeLink === 1 ? "active" : ""}`}
                onClick={() => handleClick(1)}
              >
                About Us
              </ListItem>
            </Link>

            <Link to="/expertise">
              <ListItem
                fontWeight="bolder"
                className={`nav-item ${activeLink === 2 ? "active" : ""}`}
                onClick={() => handleClick(2)}
              >
                Area Of Expertise
              </ListItem>
            </Link>

            <Link to="/product">
              <ListItem
                fontWeight="bolder"
                className={`nav-item ${activeLink === 3 ? "active" : ""}`}
                onClick={() => handleClick(3)}
              >
                Product Offerings
              </ListItem>
            </Link>

            <Link to="/partner">
              <ListItem
                fontWeight="bolder"
                className={`nav-item ${activeLink === 4 ? "active" : ""}`}
                onClick={() => handleClick(4)}
              >
                Partner With Us
              </ListItem>
            </Link>

            <Link to="/informatives">
              <ListItem
                fontWeight="bolder"
                className={`nav-item ${activeLink === 5 ? "active" : ""}`}
                onClick={() => handleClick(5)}
              >
                Informatives
              </ListItem>
            </Link>
            {/* <Link to="/experts">
            <ListItem
              fontWeight="bolder"
              className={`nav-item ${activeLink === 5 ? "active" : ""}`}
              onClick={() => handleClick(5)}
            >
              Meet Our Experts
            </ListItem>
          </Link> */}

            <Link to="/events">
              <ListItem
                fontWeight="bolder"
                className={`nav-item ${activeLink === 6 ? "active" : ""}`}
                onClick={() => handleClick(6)}
              >
                Events & Gallery
              </ListItem>
            </Link>

            <Link to="/contactus">
              <ListItem
                fontWeight="bolder"
                className={`nav-item ${activeLink === 7 ? "active" : ""}`}
                onClick={() => handleClick(7)}
              >
                Contact Us
              </ListItem>
            </Link>
          </List>
        </Box>
      </div>
    </Flex>
  );
};

export default Navbar;
