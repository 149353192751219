import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  Textarea,
  Select,
  Checkbox,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import office from "../assets/images/Contact/Office.png";
import call from "../assets/images/Contact/call.png";
import contact from "../assets/images/Contact/contact2.png";
import corporate from "../assets/images/Contact/corporate.png";
import email from "../assets/images/Contact/email.png";
import location from "../assets/images/Contact/location.png";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const [scrollY, setScrollY] = useState(0);
  const [partnerChecked, setPartnerChecked] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    category: "",
    message: "",
  });
  const [errorStatus, setErrorStatus] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [commonErrorMessage, setCommonErrorMessage] = useState("");

  const handleCheckboxChange = () => {
    setPartnerChecked(!partnerChecked);
    setFormData({ ...formData, category: "Want to Partner" });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: id === "category" ? e.target.selectedOptions[0].text : value,
    });

    setCommonErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = false;
    if (
      !formData.name ||
      !formData.email ||
      !formData.category ||
      !formData.message
    ) {
      errors = true;
    }

    if (errors) {
      setErrorStatus(true);
      setCommonErrorMessage("Please fill in the required values.");
      setTimeout(() => {
        setCommonErrorMessage("");
      }, 3000);
      return;
    }
    setSubmitDisable(true);

    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      category: formData.category,
      to_name: "Sanjay Pandya",
      message: formData.message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        setErrorStatus(false);
        setCommonErrorMessage("Email Sent Successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        setErrorStatus(true);
        setCommonErrorMessage("There was an Error. Try again later");
        setTimeout(() => {
          setCommonErrorMessage("");
          setSubmitDisable(false);
        }, 3000);
      });
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scale = 1 + scrollY * 0.0009;

  const imageStyle = {
    transform: `scale(${scale})`,
    transition: "transform 0.01s ease-in-out",
  };

  return (
    <Box pb={8} pt={32} overflow="hidden" bgColor="#edf6f9">
      <Box
        bgImage={contact}
        bgSize="cover"
        bgPosition="center"
        h="20vh"
        position="relative"
        style={imageStyle}
      >
        <Box
          position="absolute"
          top="50%"
          left="75%"
          transform="translate(-50%, -50%)"
          textAlign="center"
        >
          <Heading as="h1" size="2xl" color="black">
            Contact Us
          </Heading>
        </Box>
      </Box>
      <Flex
        flexDir={{ base: "column", md: "row" }}
        justifyContent="center"
        // bgColor="red"
        // alignItems="center"
        mx="auto"
        w="90%"
        px={{ sm: 8, md: 12 }}
        gap={12}
        mt={4}
      >
        <Box
          w={{ base: "100%", md: "50%" }}
          flex="1"
          // minW="350px"
          // mx="auto"
          p={{ base: 4, md: 8 }}
          pb={{ base: 2, md: 8 }}
          textAlign="left"
          bgColor="rgba(255, 255, 255, 0.9)"
          borderRadius="lg"
          boxShadow="lg"
        >
          <Heading as="h1" mb={8}>
            Enquire
          </Heading>
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              placeholder="Your Name"
              onChange={handleChange}
              value={formData.name}
            />
          </FormControl>

          <FormControl id="email" mt={4} isRequired>
            <FormLabel>Email Address</FormLabel>
            <Input
              type="email"
              placeholder="Your Email"
              onChange={handleChange}
              value={formData.email}
            />
          </FormControl>

          {/* Checkbox for "Partner with us" */}
          <FormControl id="partner" mt={4}>
            <Checkbox
              onChange={handleCheckboxChange}
              isChecked={partnerChecked}
            >
              Partner with us
            </Checkbox>
          </FormControl>

          {partnerChecked ? (
            <FormControl id="category" mt={4} isRequired>
              <FormLabel>Category</FormLabel>
              <Input type="text" value="Partner with us" isDisabled />
            </FormControl>
          ) : (
            <FormControl id="category" mt={4} isRequired>
              <FormLabel>Category</FormLabel>
              <Select
                placeholder="Select a category"
                onChange={handleChange}
                value={formData.category}
              >
                {/* Add 12 options */}
                <option value="Mutual Funds">Mutual Funds</option>
                <option value="Portfolio Management Scheme">
                  Portfolio Management Scheme
                </option>
                <option value="Equity">Equity</option>
                <option value="Health Insurance">Health Insurance</option>
                <option value="Life Insurance">Life Insurance</option>
                <option value="Pension Planning">Pension Planning</option>
                <option value="Business Insurance">Business Insurance</option>
                <option value="Married Women's Property Act">
                  Married Women's Property Act
                </option>
                <option value="Group Insurance">Group Insurance</option>
                <option value="General Insurance">General Insurance</option>
                <option value="Legacy Planning">Legacy Planning</option>
                <option value="Wealth Advisory">Wealth Advisory</option>
              </Select>
            </FormControl>
          )}

          <FormControl id="message" mt={4} isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea
              placeholder="Your Message"
              onChange={handleChange}
              value={formData.message}
            />
          </FormControl>
          {commonErrorMessage && (
            <Box color={errorStatus ? "red" : "green"} mt={4} mb={4}>
              <b>{commonErrorMessage}</b>
            </Box>
          )}
          <Button
            type="button"
            mt={4}
            onClick={handleSubmit}
            colorScheme="blue"
            w="100%"
            disabled={submitDisable}
          >
            Submit
          </Button>
        </Box>
        <Flex
          flexDir="column"
          justifyContent="center"
          gap={12}
          alignItems="center"
          flex="1"
        >
          <Box
            p="4"
            bg="white"
            rounded="lg"
            boxShadow="lg"
            mb="4"
            borderBottom="5px solid #51abcb"
            borderLeft="5px solid #51abcb"
            borderRight="5px solid #51abcb"
            minW="200px"
            maxW="500px"
          >
            <Flex alignItems="center">
              {" "}
              <Box mr="4">
                {" "}
                <Image src={office} alt="Corporate Icon" maxH="40px" />
              </Box>
              <Heading as="h2" size="lg" color="gray.700">
                Office
              </Heading>
            </Flex>
            <Divider mt="2" />
            <Flex alignItems="center" mt="4">
              <Box mr="4">
                {" "}
                <Image src={location} alt="Corporate Icon" maxH="30px" />
              </Box>
              <Text color="gray.700">
                701/B, NG Garden, Gaurav Garden Complex,
                <br /> Kandivali (West), Mumbai – 400 067,
                <br /> Maharashtra, India
              </Text>
            </Flex>

            <Flex alignItems="center" mt="2">
              <Box mr="4">
                {" "}
                <Image src={email} alt="Corporate Icon" maxH="30px" />
              </Box>
              <Text color="gray.600">wealth.jewelss@gmail.com </Text>
            </Flex>
            <Flex alignItems="center" mt="4">
              <Box mr="4">
                {" "}
                <Image src={call} alt="Corporate Icon" maxH="30px" />
              </Box>
              <Text color="gray.600">+91 93222 81468 | +91 93726 73958</Text>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ContactUs;
