import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/ImageSlider.css";
import { Box, Heading, Text } from "@chakra-ui/react";

const ImageSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const imgText = [
    "Financial Planning",
    "Systematic Growth of Investments",
    "Comprehensive Protection",
    "",
    "Equity & Mutual Funds",
    "Legacy Planning",
    "Wealth Advisory",
  ];

  const textStyle = [
    { top: "", left: "", bottom: "40px", right: "22vw", color: "black.400" },
    { top: "30px", left: "1vw", bottom: "", right: "", color: "black.400" },
    { top: "20px", left: "", bottom: "", right: "1vw", color: "black.400" },
    { top: "40px", left: "1vw", bottom: "", right: "", color: "black.400" },
    { top: "", left: "", bottom: "50px", right: "5vw", color: "black.400" },
    { top: "20px", left: "1vw", bottom: "", right: "", color: "black.400" },
    { top: "30px", left: "", bottom: "", right: "1vw", color: "black.400" },
  ];

  return (
    <Box position="relative" mt={12}>
      <Slider {...settings} className="image-slider">
        {images.map((imageUrl, index) => (
          <div key={index}>
            <div className="image-container">
              <img
                src={imageUrl.img}
                alt={`Image ${index + 1}`}
                className="slider-image"
              />
              <Heading
                position="absolute"
                top={textStyle[index].top}
                bottom={textStyle[index].bottom}
                right={textStyle[index].right}
                left={textStyle[index].left}
                fontWeight="bolder"
                as="h1"
                size={{ sm: "lg", md: "3xl" }}
                color={textStyle[index].color}
                fontFamily="Oxygen"
                className="text-overlay"
                textShadow="2px 2px 2px rgba(0, 0, 0, 0.5)"
                backgroundColor="rgba(255, 255, 255, 0.7)"
              >
                {imgText[index]}
              </Heading>
              <div className="slick-dots-container">
                <ul className="slick-dots" />
              </div>
              {/* <Box
                position="absolute"
                top="90%"
                left="30%"
                transform="translate(-50%, -50%)"
                textAlign="center"
              >
                <Heading
                  as="h1"
                  size="2xl"
                  color="black.800"
                  fontFamily="Oxygen"
                >
                  {imageUrl.imgText}
                </Heading>
              </Box> */}
              {/* <div className="image-container">
              <img
                src={imageUrl}
                alt={`Image ${index + 1}`}
                className="slider-image"
              />
              <div className="slick-dots-container">
                <ul className="slick-dots" />
              </div> */}
              {/* <div className="image-overlay">
                <div className="construction-text">
                  <Heading
                    as="h1"
                    size="lg"
                    fontSize={{ base: "l", md: "4xl" }}
                    color="white"
                  >
                    Financial Planning
                  </Heading>
                </div>
              </div> */}
              {/* </div> */}
            </div>
          </div>
        ))}
      </Slider>
    </Box>
  );
};
export default ImageSlider;
