import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "../assets/css/WhatsappButton.css";

const WhatsappButton = () => {
  const predefinedMessage =
    "*Please share more details about Collaborative Partnership & or Financial Product offering!!*";

  const encodedMessage = encodeURIComponent(predefinedMessage);
  const whatsappUrl = `https://wa.me/+919322281468?text=${encodedMessage}`;
  return (
    <a
      href={whatsappUrl}
      className="chat-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp className="chat-icon" />
      <span className="chat-text">Reach Out!</span>
    </a>
  );
};

export default WhatsappButton;
