import React from "react";
import "../assets/css/PartnerCard.css";
import { Link } from "react-router-dom";
import { Button, Flex } from "@chakra-ui/react";
import { Divider } from "@chakra-ui/react";
const PartnerCard = ({ imageSrc, title, content }) => {
  return (
    <div className="partner-card">
      <div className="imgBox">
        <img src={imageSrc} alt={title} />
      </div>
      <div className="content">
        <h2>{title}</h2>
        <Divider mt="2" />

        <Flex justifyContent="center">
          <Link to="/contactus">
            <Button mt={2} mx="auto">
              Enquire
            </Button>
          </Link>
        </Flex>
      </div>
    </div>
  );
};

export default PartnerCard;
