import {
  Box,
  Flex,
  Heading,
  Image,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import entrepreneurship from "../assets/images/Partner/Entrepreneurship.jpg";
import brand from "../assets/images/Partner/brand.png";
import career from "../assets/images/Partner/career.png";
import support from "../assets/images/Partner/digitalSupport.png";
import earning from "../assets/images/Partner/earning.png";
import handshake from "../assets/images/Partner/handshake.png";
import partner from "../assets/images/Partner/partner.png";
import professional from "../assets/images/Partner/professionalDevelopment.png";
import service from "../assets/images/Partner/service.png";
import womenEmpowerment from "../assets/images/Partner/womenEmpowerment.png";
import PartnerCard from "../components/PartnerCard.js";
const Partner = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Adjust the multiplier for desired zoom intensity

  // Apply a smooth transition for the image scaling
  const opacity = 1 - scrollY / 800;
  const scale = 1 + scrollY * 0.0003;
  const imageStyle = {
    transform: `scale(${scale})`,
    opacity: opacity < 0 ? 0 : opacity > 1 ? 1 : opacity,
    transition: "transform 0.01s ease-in-out,opacity 0.03s ease-in-out",
  };
  return (
    <Box
      pt={36}
      overflow="hidden"
      // minH="100vh"
      bgColor="#edf6f9"
      // bgGradient="linear(to-b, #858685, #1a1b1a)"
      fontFamily="Oxygen"
    >
      {/* <Box
        bgImage={handshake}
        bgSize="cover"
        bgPosition="center"
        h="25rem"
        position="relative"
        style={imageStyle}
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
        >
          <Heading
            as="h1"
            size={{ base: "xl", md: "3xl" }}
            textShadow="1px 1px 2px rgba(0, 0, 0, 0.3)"
            fontWeight="bold"
            color="black"
          >
            Partner With Us
          </Heading>
        </Box>
      </Box> */}
      <Heading
        textAlign="center"
        as="h1"
        fontSize={{ base: "3xl", md: "5xl" }}
        fontWeight="bold"
        mt={8}
        ml={{ base: 4, md: 24 }}
        position="relative"
        display="inline-block"
        _after={{
          content: '""',
          position: "absolute",
          bottom: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "75%",
          height: "4px",
          bgColor: "teal.500",
          borderRadius: "2px",
        }}
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.1)"
      >
        Partner With Us
      </Heading>
      <Flex
        // bgColor="red"
        py={{ base: 8, md: 4 }}
        style={{ fontFamily: "Oxygen" }}
        flexDir={{ sm: "column", md: "row" }} // Set the flex direction to column
        justifyContent="center"
        alignItems="center"
        gap={10}
        // bgColor="red"
      >
        <Flex
          p={{ base: 4, md: 6 }}
          // pb={{ base: 2, md: 8 }}
          maxW={{ base: "90%", md: "55%" }}
          textAlign="left"
          bgColor="rgba(255, 255, 255, 0.9)"
          borderRadius="lg"
          boxShadow="lg"
        >
          <UnorderedList
            lineHeight="2"
            listStyleType="disc"
            ml={{ base: 6, md: 8 }}
            fontSize={{ base: "md", md: "lg" }}
            mt={2}
          >
            <ListItem
              fontWeight="bold"
              color="#333"
              fontSize={{ base: "md", md: "lg" }}
            >
              Inviting{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                aspiring entrepreneurs
              </span>{" "}
              with a dream to grow big, possessing the{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                passion
              </span>{" "}
              and capability to pursue their journey.
            </ListItem>
            <ListItem
              fontWeight="bold"
              color="#333"
              fontSize={{ base: "md", md: "lg" }}
            >
              Those who dream of starting their{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                own business
              </span>
              .
            </ListItem>
            <ListItem
              fontWeight="bold"
              color="#333"
              fontSize={{ base: "md", md: "lg" }}
            >
              Entrepreneurs seeking to establish their business with{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                minimal
              </span>{" "}
              upfront costs and operate{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                independently
              </span>
              .
            </ListItem>
            <ListItem
              fontWeight="bold"
              color="#333"
              fontSize={{ base: "md", md: "lg" }}
            >
              You're chasing your{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                dream
              </span>{" "}
              with passion and capability, yet unaware of the{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                opportunities
              </span>{" "}
              and challenges ahead on your journey.
            </ListItem>
            <ListItem
              fontWeight="bold"
              color="#333"
              fontSize={{ base: "md", md: "lg" }}
            >
              <span className="underlineText" style={{ color: "#e89f69" }}>
                {" "}
                Successful
              </span>{" "}
              goal journeys require guide maps, and we've built{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                experience
              </span>{" "}
              and{" "}
              <span className="underlineText" style={{ color: "#e89f69" }}>
                expertise
              </span>{" "}
              along the way that you can use.
            </ListItem>
          </UnorderedList>
        </Flex>
        <Image
          src={partner}
          alt="Partner"
          maxH="400px"
          // width="30%"
          objectFit="cover"
          // borderRadius="lg"
          // boxShadow="lg"
          bgColor="transparent"
          // scale={2}
          display={{ base: "none", md: "block" }}
        />
      </Flex>
      <Flex
        justifyContent="center"
        py={8}
        alignItems="center"
        flexDir="row"
        flexWrap="wrap"
        gap={10}
        minH="80vh"
      >
        <PartnerCard
          imageSrc={brand}
          title="Brand Association"
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, aut!"
        />
        <PartnerCard
          imageSrc={career}
          title="Career Opportunity"
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, aut!"
        />
        <PartnerCard
          imageSrc={earning}
          title=" Earning Opportunity"
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, aut!"
        />
        <PartnerCard
          imageSrc={entrepreneurship}
          title=" Entrepreneurial Career"
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, aut!"
        />
        <PartnerCard
          imageSrc={professional}
          title="Professional Development"
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, aut!"
        />
        <PartnerCard
          imageSrc={support}
          title=" Digital Support"
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, aut!"
        />
        <PartnerCard
          imageSrc={service}
          title="Service Offering"
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, aut!"
        />
        <PartnerCard
          imageSrc={womenEmpowerment}
          title="Entrepreneurial Empowerment Program for Women"
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, aut!"
        />
      </Flex>
    </Box>
  );
};

export default Partner;
