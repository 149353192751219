import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import Navbar from "./components/Navbar.js";
import Home from "./pages/Home.js";
import AboutUs from "./pages/AboutUs.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Expertise from "./pages/Expertise.js";
import ProductOfferings from "./pages/ProductOfferings.js";
import Partner from "./pages/Partner.js";
// import Experts from "./pages/Experts.js";
import Events from "./pages/Events.js";
import ContactUs from "./pages/ContactUs.js";
import Informatives from "./pages/Informatives.js";
import Footer from "./components/Footer.js";
import WhatsappButton from "./components/WhatsappButton.js";
function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/product" element={<ProductOfferings />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/informatives" element={<Informatives />} />
          <Route path="/events" element={<Events />} />

          <Route path="/contactus" element={<ContactUs />} />
          {/* <Route path="/experts" element={<Experts />} /> */}
        </Routes>
        <WhatsappButton />
        <Footer />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
