import React, { useState, useEffect } from "react";
import { Box, Image, Flex } from "@chakra-ui/react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "../assets/css/ImageCarousel.css";

const ImageCarousel = ({ imgUrl }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    focusOnSelect: true,
    autoplaySpeed: 2000,
    centerMode: true,
    beforeChange: (current, next) => setSlideIndex(next),
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setSlidesToShow(1);
      } else if (window.innerWidth > 481 && window.innerWidth <= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="slider">
      <Slider {...settings}>
        {imgUrl.length > 0 &&
          imgUrl.map((img, index) => (
            <div
              className={index === slideIndex ? "slide slide-active" : "slide"}
              key={index}
            >
              <img src={img} alt="" />
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
