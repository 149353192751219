import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import events from "../assets/images/Events/events.png";
import ImageCarousel from "../components/ImageCarousel.js";
import event1 from "../assets/images/Events/events1.jpg";
import event2 from "../assets/images/Events/events2.jpg";
import event3 from "../assets/images/Events/events3.jpg";
import event4 from "../assets/images/Events/events4.jpg";
import event5 from "../assets/images/Events/events5.jpg";
import event6 from "../assets/images/Events/events6.jpg";
import event7 from "../assets/images/Events/events7.jpg";
import event8 from "../assets/images/Events/events8.jpg";
import event9 from "../assets/images/Events/events9.jpg";
import event10 from "../assets/images/Events/events10.jpg";
import event11 from "../assets/images/Events/events11.jpg";
import event12 from "../assets/images/Events/events12.jpg";
import event13 from "../assets/images/Events/events13.jpg";
import gallery1 from "../assets/images/Events/gallery1.jpg";
import gallery2 from "../assets/images/Events/gallery2.jpg";
import gallery3 from "../assets/images/Events/gallery3.jpg";
import gallery4 from "../assets/images/Events/gallery4.jpg";
import gallery5 from "../assets/images/Events/gallery5.jpg";
import gallery6 from "../assets/images/Events/gallery6.jpg";
import gallery7 from "../assets/images/Events/gallery7.jpg";
import gallery8 from "../assets/images/Events/gallery8.jpg";
import gallery9 from "../assets/images/Events/gallery9.jpg";
import gallery10 from "../assets/images/Events/gallery10.jpg";
import gallery11 from "../assets/images/Events/gallery11.jpg";
import gallery12 from "../assets/images/Events/gallery12.jpg";
import gallery13 from "../assets/images/Events/gallery13.jpg";
import gallery14 from "../assets/images/Events/gallery14.jpg";

const event = [
  event1,
  event2,
  event3,
  event4,
  event5,
  event6,
  event7,
  event8,

  event10,
  event11,
  event12,
  event13,
];

const gallery = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
];
const Events = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Adjust the multiplier for desired zoom intensity

  // Apply a smooth transition for the image scaling
  const opacity = 1 - scrollY / 800;
  const scale = 1 + scrollY * 0.0003;
  const imageStyle = {
    transform: `scale(${scale})`,
    opacity: opacity < 0 ? 0 : opacity > 1 ? 1 : opacity,
    transition: "transform 0.01s ease-in-out,opacity 0.03s ease-in-out",
  };
  return (
    <Box
      pt={36}
      overflow="hidden"
      // minH="100vh"
      // bgColor="#edf6f9"
      // bgGradient="linear(100deg, rgb(236,213,232) 0% rgb(50,112,118)100%)"
      // bgGradient={"linear(to-r,white, #ffddd2)"}
      fontFamily="Oxygen"
      style={{
        background: "rgb(146,247,255)",
        background:
          "linear-gradient(90deg, rgba(146,247,255,0.2861519607843137) 33%, rgba(255,237,207,0.5270483193277311) 100%)",
      }}
    >
      <Heading
        textAlign="center"
        as="h1"
        fontSize={{ base: "3xl", md: "5xl" }}
        fontWeight="bold"
        m={8}
        ml={24}
        position="relative"
        display="inline-block"
        _after={{
          content: '""',
          position: "absolute",
          bottom: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "75%",
          height: "4px",
          bgColor: "teal.500",
          borderRadius: "2px",
        }}
        textShadow="2px 2px 4px rgba(0, 0, 0, 0.1)"
      >
        Events & Gallery
      </Heading>
      <Flex
        // overflow="hidden"
        flexDir="column"
        mb={4}
        // bgColor="red"
        // style={{
        //   background: "rgb(0,0,0)",
        //   background:
        //     "linear-gradient(90deg, rgba(0,0,0,0.4150035014005602) 0%, rgba(255,255,255,0) 100%)",
        // }}
      >
        <Flex flexDir="column" overflow="hidden">
          <Box
            // flex="35%"
            display="flex"
            flexDir="column"
            // h="100vh"
            justifyContent="center" // Center horizontally
            alignItems="center" // Center vertically
            // p={4}
            lineHeight="1.7"
            mb={12}
          >
            <Heading
              as="h2"
              alignItems="center"
              fontFamily="Oxygen"
              size={{ base: "xl", md: "xl", lg: "2xl" }}
              // color="#ebb5a1"
            >
              Events
            </Heading>
          </Box>
          <ImageCarousel imgUrl={event} />
        </Flex>

        <Box
          // flex="35%"
          display="flex"
          flexDir="column"
          // h="100vh"
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          // p={4}
          lineHeight="1.7"
          my={12}
        >
          <Heading
            // color="#ebb5a1"
            as="h2"
            alignItems="center"
            fontFamily="Oxygen"
            size={{ base: "xl", md: "xl", lg: "2xl" }}
          >
            Gallery
          </Heading>
        </Box>
        <ImageCarousel imgUrl={gallery} />
      </Flex>
    </Box>
  );
};

export default Events;
